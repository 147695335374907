import { Injectable, OnDestroy } from '@angular/core';
import { SubmittalRequestDetail } from 'app/main/pages/dashboard/scrumboard/project.model';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StakeholderProjectDetail } from './models/stakeholder-project';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { HttpClient } from '@angular/common/http';
import { ProjectFinanceGroup, StakeholderPermission } from 'app/main/models/projects.model';
import { formatDate } from '@angular/common';
import { AuthService } from 'app/main/services/auth.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StakeholderProjectsService implements OnDestroy {

  apiUrl = environment.basePath;
  submittalUrl = this.apiUrl + '/submittals';
  projectUrl = this.apiUrl + '/projects';
  pageSize = 30;
  fileUploadLimit = 10;

  private onSHProjectChange = new BehaviorSubject<StakeholderProjectDetail | null>(null);
  public projectDetail = this.onSHProjectChange.asObservable();

  private onExitSHDashboard = new BehaviorSubject(null);
  public removeSHNavigation = this.onExitSHDashboard.asObservable();

  public userProjectRole = {   ///used in permission service
    role: "",
    projPermission: {
      pcr: { create: false, approveReject: false },
      rfi: { create: false },
      submittals: { create: false, approveReject: false }
    }
  };

  /**
   * Owner's fuse navigation config
   */
  private readonly ownerNav = {
    id: "owner",
    title: "Owner",
    type: "group",
    children: [
      {
        id: "owner-dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: ""

      },
      {
        id: "updates",
        title: "Project Updates",
        type: "item",
        icon: "updates",
        url: ""

      },
      {
        id: "pcr",
        title: "PCR",
        type: "item",
        icon: "pcr",
        url: ""

      },
      {
        id: "rfi",
        title: "RFI",
        type: "item",
        icon: "rfi",
        url: ""

      },
      {
        id: "submittal",
        title: "Submittals",
        type: "item",
        icon: "submittals",
        url: ""

      },
      {
        id: "shchat",
        title: "Chat",
        type: "item",
        icon: "custom-chat",
        url: "",
        badge: {
          title: 0,
          bg: "#ea1601",
          fg: "white",
        }

      },
      {
        id: "billing",
        title: "Billing",
        type: "item",
        icon: "finance",
        url: ""

      },
    ]
  };

  /**
   * Architect's fuse navigation config
   */
  architectNav = {
    id: "architect",
    title: "Architect",
    type: "group",
    children: [
      {
        id: "architect-dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: "",
      },
      {
        id: "pcr",
        title: "PCR",
        type: "item",
        icon: "pcr",
        url: "",
      },
      {
        id: "rfi",
        title: "RFI",
        type: "item",
        icon: "rfi",
        url: ""
      },
      {
        id: "submittal",
        title: "Submittals",
        type: "item",
        icon: "submittals",
        url: ""
      },
      {
        id: "shchat",
        title: "Chat",
        type: "item",
        icon: "custom-chat",
        url: "",
        badge: {
          title: 0,
          bg: "#ea1601",
          fg: "white",
        }

      },
    ]
  }

  private onChatSelected = new BehaviorSubject<any>(null);
  public selectedChat = this.onChatSelected.asObservable();

  reorderContactsList = new Subject();

  onSelectedChatUpdated = new Subject();

  private _unsubscribe = new Subject();

  constructor(
    private baseService: BaseService,
    private _fuseNavigationService: FuseNavigationService,
    private _httpClient: HttpClient,
    private authService: AuthService
  ) { }

  /**
   * Lifecycle hook: OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Get stakeholder's projects list
   * @param sortByName 
   * @param pageNo 
   * @param searchString 
   * @returns {Observable<any>}
   */
  getStakeholderProjects(sortByName: boolean, pageNo: number, searchString) {
    let api = "/users/projects-list?";
    if (sortByName)
      api = api + "sort=name&";
    return this.baseService.get(this.apiUrl + api + `pageNumber=${pageNo}&pageSize=${this.pageSize}&name=${searchString}`, true,);
  }

  /**
   * Get project detail by id
   * @param byId : string -project id
   * @returns {Observable<any>}
   */
  getStakeholderProjectById(byId) {
    return this.baseService.get(this.apiUrl + "/projects/" + byId, true);
  }

  /**
   * Set project detail info
   * @param data 
   */
  setProject(data: any) {
    if (data) {
      let project: StakeholderProjectDetail = {
        _id: data._id,
        address: data.address,
        boardDetail: data.BoardsDetails,
        endDate: data.endDate,
        isCollaborated: data.isCollaborated,
        location: data.location,
        loginUserProjectDetails: data.loginUserProjectDetails,
        name: data.name,
        startDate: data.startDate,
        projectUpdates: data.projectUpdates,
        upcommingTasks: data.upcommingTasks,
        owner: data.owner,
        finances: data.finances ? data.finances : []
      };

      if (project.finances.length > 0) {
        let masterIndex = -1;
        //calculate total of billed and unbilled of Master & PCRs
        project.finances.forEach((obj: ProjectFinanceGroup, index) => {
          if (obj.financeName.includes('PCR')) {
            obj.financeType = 'pcr';
            obj.totalUnbilled = Math.round(obj.financeEntries.reduce((a, b) => a + b['unbilled'], 0));
            obj.totalBilled = Math.round(obj.financeEntries.reduce((a, b) => {
              let billed = b['isCancelled'] ? 0 : b['billed'];
              return a + billed;
            }, 0));
          }
          else {
            masterIndex = index;
            obj.financeType = 'master';

            obj.totalBilled = Math.round(obj.financeEntries.reduce((a, b) => a + b['billed'], 0));
            obj.totalUnbilled = obj.financeValue - obj.totalBilled;
          }
        });

        if (masterIndex != -1)
          project.finances.unshift(project.finances.splice(masterIndex, 1)[0]);  //set master agreement on top
      }

      if (data.chatDetails) project.chatDetails = data.chatDetails.chats;

      if (data.parent) project.parent = data.parent;

      if (project.upcommingTasks.UpcommingTasks.length > 0) {
        let cards = 0, subp = 0;

        project.upcommingTasks.UpcommingTasks.forEach(obj => {
          cards += obj.idCardsCount;
          subp += obj.idProjectsCount;

          let startdates = obj.idCards.map(itm => itm.start);
          startdates = startdates.concat(obj.idProjects.map(itm => itm.startDate));

          let enddates = obj.idCards.map(itm => itm.end);
          enddates = enddates.concat(obj.idProjects.map(itm => itm.endDate));


          const minDate = new Date(Math.min(...startdates.map(date => Date.parse(date))));
          const maxDate = new Date(Math.max(...enddates.map(date => Date.parse(date))));
          if (minDate instanceof Date && !isNaN(minDate.getTime()))
            obj.start = minDate;
          else obj.start = null;

          if (maxDate instanceof Date && !isNaN(maxDate.getTime()))
            obj.end = maxDate;
          else obj.end = null;
        });


        project.upcommingTasks.totalCards = cards;
        project.upcommingTasks.totalProjects = subp;

      }
      else {
        project.upcommingTasks.totalCards = 0;
        project.upcommingTasks.totalProjects = 0;
      }

      if (data.subprojects) project.subprojects = data.subprojects;
      if (data.parentCompany) project.parentCompany = data.parentCompany;
      if (data.cards) project.cards = data.cards;

      this.onSHProjectChange.next(project);
    }
    else this.onSHProjectChange.next(null);
  }

  /**
   * Set navigation for architect and owners
   * @param projectId : project id
   * @param role : user's project role object
   */
  setNavigation(projectId: string, projectRole: any, collaborated: boolean) {
    let current: Array<any> = this._fuseNavigationService.getCurrentNavigation();
    if (current.length > 1) this._fuseNavigationService.removeNavigationItem(current[1].id);

    this.userProjectRole.role = projectRole.role.toLowerCase().includes("owner") ? "owner" : "architect";
    let navitem = null;
    if (this.userProjectRole.role == "owner") {
      let permission = projectRole.permission ? projectRole.permission.permissions : null;
      navitem = JSON.parse(JSON.stringify(this.ownerNav));

      navitem.children[0].url = "/stakeholder-users/projects/project/" + projectId;
      navitem.children[1].url = "/stakeholder-users/projects/project/" + projectId + "/updates";
      if (permission && permission.pcr.approveReject) {
        navitem.children[2].url = "/stakeholder-users/projects/project/" + projectId + "/pcr/requests";
        this.userProjectRole.projPermission.pcr.approveReject = true;
      }
      else this.userProjectRole.projPermission.pcr.approveReject = false;

      navitem.children[3].url = "/stakeholder-users/projects/project/" + projectId + "/rfi/requests";
      if (permission && permission.rfi) {
        this.userProjectRole.projPermission.rfi.create = permission.rfi.create;
      }
      else this.userProjectRole.projPermission.rfi.create = false;

      if (permission && permission.submittals.approveReject) {
        navitem.children[4].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
        this.userProjectRole.projPermission.submittals.approveReject = true;
      }
      else this.userProjectRole.projPermission.submittals.approveReject = false;

      navitem.children[5].url = "/stakeholder-users/projects/project/" + projectId + "/chat";
      navitem.children[6].url = "/stakeholder-users/projects/project/" + projectId + "/billing";

      this.authService.user
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(user => {
          navitem.children[5].badge.title = user.chatsUnreadCount;
        });


      // navitem = this.ownerNav;

    }
    else {
      let permission = projectRole.permission ? projectRole.permission.permissions : null;
      navitem = JSON.parse(JSON.stringify(this.architectNav));

      navitem.children[0].url = "/stakeholder-users/projects/project/" + projectId; //dashboard

      if (permission && permission.pcr) {
        let createNav = false;
        if (collaborated && (permission.pcr.create || permission.pcr.approveReject))
          createNav = true;
        else if (!collaborated && permission.pcr.approveReject)
          createNav = true;

        if (createNav)
          navitem.children[1].url = "/stakeholder-users/projects/project/" + projectId + "/pcr/requests";  //pcr
        this.userProjectRole.projPermission.pcr.approveReject = permission.pcr.approveReject;
        this.userProjectRole.projPermission.pcr.create = permission.pcr.create;
      }
      else {
        this.userProjectRole.projPermission.pcr.approveReject = false;
        this.userProjectRole.projPermission.pcr.create = false;
      }

      navitem.children[2].url = "/stakeholder-users/projects/project/" + projectId + "/rfi/requests"; //rfi
      if (permission && permission.rfi.create) {

        this.userProjectRole.projPermission.rfi.create = true;
      }
      else this.userProjectRole.projPermission.rfi.create = false;

      if (permission && permission.submittals.approveReject) {
        navitem.children[3].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";  //submittals
        this.userProjectRole.projPermission.submittals.approveReject = true;
      }
      else this.userProjectRole.projPermission.submittals.approveReject = false;

      navitem.children[4].url = "/stakeholder-users/projects/project/" + projectId + "/chat";

    }

    // this.navigation = navStakeholder;
    this._fuseNavigationService.addNavigationItem(navitem, "end");
    // this._fuseNavigationService.unregister('main');
    // this._fuseNavigationService.register('main', this.navigation);
    this._fuseNavigationService.setCurrentNavigation('main');
  }

  /**
   * Update chat unread count
   * @param newCount : payload object
   * @returns {Observable<any>}
   */
  updateChatUnreadCount(newCount) {
    return this.baseService.post(this.apiUrl + "/users/chatunreadcount", true, { newCount: newCount });
  }

  /**
   * Update the value of the behaviour subject to remove navigation
   */
  exitNavigation() {
    this.onExitSHDashboard.next('remove');
  }

  /**
   * Download latest update attachments
   * @param mediaId 
   * @returns {Observable<any>}
   */
  downloadLatestUpdateAttachments(mediaId: string) {
    return this._httpClient.get(this.apiUrl + "/media/projectUpdates/" + mediaId, { responseType: 'blob' });
  }

  /**
   * Get received submittals request
   * @param projectid : string
   * @param currentPage : number
   * @param srchKey 
   * @param status 
   * @param sortOrder 
   * @returns {Observable<any>}
   */
  getReceivedSubmittals(projectid, currentPage, srchKey: string, status: string, sortOrder: number) {
    return this.baseService.get(this.submittalUrl + `/received/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&search=${srchKey}&status=${status}${sortOrder == -1 ? '&sort=-1' : ''}`, true);
  }

  /**
   * Get submittal detail by id
   * @param id 
   * @returns {Observable<any>}
   */
  getSubmittalDetailById(id: string) {
    return this.baseService.get(this.submittalUrl + '/' + id, true);
  }

  /**
   * Get icon name from fileType to display 
   * @param fileType 
   * @returns {Observable<any>}
   */
  getFileExtension(fileType: string): string {
    if (fileType.includes('image/bmp') || fileType.includes('image/gif') || fileType.includes('image/jpeg') || fileType.includes('image/tiff') || fileType.includes('image/png')) return 'image';
    else if (fileType.includes('text/plain') || fileType.includes('rtf') || fileType.includes('wordprocessingml') || fileType.includes('msword') || fileType.includes('opendocument.text')) return 'doc';
    else if (fileType.includes('pdf')) return 'pdf';
    else if (fileType.includes('ms-excel') || fileType.includes('spreadsheetml') || fileType.includes('spreadsheet') || fileType.includes('csv')) return 'xls';
    else if (fileType.includes('ms-powerpoint') || fileType.includes('presentation')) return 'gd-ppt';
    else if (fileType.includes('video')) return 'db-video';
    else if (fileType.includes('acad') || fileType.includes('dwg')) return 'dwg';
    else if (fileType.includes('dxf')) return 'dxf';
    else return 'general';
  }

  /**
   * Get submittal comments
   * @param id : string - submittal id
   * @param currentPage 
   * @returns {Observable<any>}
   */
  getSubmittalComments(id, currentPage: number) {
    return this.baseService.get(this.submittalUrl + `/comments/${id}?pageNumber=${currentPage}&pageSize=30`, true);
  }

  /**
   * Add submittal comment
   * @param data : payload object
   * @param id : string - submittal id
   * @returns {Observable<any>}
   */
  addSubmittalComment(data, id) {
    return this.baseService.patch(this.submittalUrl + '/add-comment/' + id, true, data);
  }

  /**
   * Generate submittal's reviewer flow
   * @param submittal 
   * @returns {SubmittalRequestDetail}
   */
  createReviewerFlow(submittal: SubmittalRequestDetail): SubmittalRequestDetail {
    submittal.reqFlow = [];
    submittal.reqFlow.push({
      id: submittal.issuer._id._id,
      name: submittal.issuer._id.name,
      status: submittal.issuer.status,
      img: submittal.issuer._id.img,
      role: 'Sub-Contractor',
      serial: -1,
      email: submittal.issuer._id.email,
      location: submittal.issuer._id.location,
      phone: submittal.issuer._id.phone
    });

    submittal.reqFlow.push({
      id: submittal.owner._id._id,
      name: submittal.owner._id.name,
      status: submittal.owner.status,
      img: submittal.owner._id.img,
      role: 'General Contractor',
      serial: 0,
      email: submittal.owner._id.email,
      location: submittal.owner._id.location,
      phone: submittal.owner._id.phone
    })

    submittal.respondents.forEach(obj => {
      submittal.reqFlow.push({
        id: obj._id._id,
        name: obj._id.name,
        status: obj.status,
        img: obj._id.img,
        role: obj.role,
        serial: obj.serialNumber,
        email: obj._id.email,
        location: obj._id.location,
        phone: obj._id.phone
      });
    });

    return submittal;
  }

  /**
   * Get project updates
   * @param projectId : project id
   * @param currentPage : current page number to fetch
   * @param srchKey : search keyword
   * @param dated : update date
   * @returns {Observable}
   */
  getProjectUpdates(projectId: string, currentPage: number, srchKey: string, dated: string) {
    return this.baseService.get(this.projectUrl + `/projectUpdates/${projectId}?page=${currentPage}&limit=${10}&comments=${srchKey}&startDate=${dated}`, true)
  }

  /**
   * Get issued RFI requests list
   * @param projectid : project id
   * @param currentPage : current page number
   * @param status : request status
   * @param srchKey : search string
   * @returns {Observable<any>}
   */
  getIssuedRFIs(projectid: string, currentPage: number, status: string, srchKey: string) {
    return this.baseService.get(this.apiUrl + `/rfi/issued/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&status=${status}&search=${srchKey}`, true);
  }

  /**
   * get division/list name based on page size
   * @param currentPage : current page no.
   * @param list : list name to search
   * @returns : observable
   */
  getAllDivisions(currentPage, list) {
    let searchParams = {
      divisionName: list,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/divisionLists/divisions', true, searchParams);
  }

  /**
   * get sub-division/sub-groups by page size
   * @param currentPage : current page no. to fetch
   * @param card : search keyword
   * @param divisionListId : parent division id
   * @returns : observable
   */
  getAllPlanCards(currentPage, card, divisionListId) {
    let searchParams = {
      divisionListId: divisionListId,
      cardName: card,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/divisionCards/cardsList', true, searchParams);
  }

  /**
   * Get users for chat
   * @returns {Observable<any>}
   */
  getUsersForChat(searchTerm: string, projectId: string, pageNumber: number, limit: number) {
    return this.baseService.get(this.apiUrl + `/users/stakeHolderChats?search=${searchTerm}&projectId=${projectId}&page=${pageNumber}&limit=${limit}`, true);
  }

  /**
   * Get chats/convsersations of a user
   * @param chatId : string
   * @param limit: page size
   * @param skip: 
   * @returns {Observable<any>}
   */
  getChats(chatId, limit: number, skip: number) {
    return this.baseService.get(this.apiUrl + `/chats/get_message_by_chat_id/${chatId}?limit=${limit}&skip=${skip}`, true);
  }

  setSelectedChat(data) {
    this.onChatSelected.next(data);
  }


  /**
   * reverse an array
   * @returns {Array<any>}
   */
  reversArray(array) {
    var left = null;
    var right = null;
    var length = array.length;
    for (
      left = 0, right = length - 1;
      left < right;
      left += 1, right -= 1
    ) {
      var temporary = array[left];
      array[left] = array[right];
      array[right] = temporary;
    }
    return array;
  }

  /**
   * Arrange message by date
   * @param chat : chat object
   * @param startPos : starting position
   * @param totalLen : length of message
   */
  groupByDate(chat, startPos, totalLen) {

    if (startPos == 0) startPos = 1;
    for (var index = startPos - 1; index < totalLen; index++) {

      if (index == 0)
        chat.messages[index].divider = { divider: true, value: chat.messages[index].dateCreated };
      else {
        if ((new Date(chat.messages[index].dateCreated).setHours(0, 0, 0, 0)) > (new Date(chat.messages[index - 1].dateCreated).setHours(0, 0, 0, 0))) {
          chat.messages[index].divider = { divider: true, value: chat.messages[index].dateCreated };

        }
        else
          chat.messages[index].divider = { divider: false, value: null };
      }

      if (chat.messages[index].divider.divider == true) {

        let currentDate = new Date().setHours(0, 0, 0, 0);
        let msgDate = new Date(chat.messages[index].dateCreated).setHours(0, 0, 0, 0);
        if (currentDate == msgDate)
          chat.messages[index].divider.value = "Today";
        else
          chat.messages[index].divider.value = formatDate(msgDate, 'yyyy-MMM-dd', 'en');

      }
    }
  }

  /**
   * Add chat messages
   * @param data : payload object
   * @returns {Observable<any>}
   */
  addChatMessage(data) {
    return this.baseService.post(this.apiUrl + "/chats/add_Message", true, data);
  }

  /**
   * Reorders chat by date
   * @param chats : chat object
   */
  reorderChat(chats) {
    chats.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        +new Date(b.lastMessage.dateCreated) -
        +new Date(a.lastMessage.dateCreated)
      );
    });
  }

  /**
   * Adjust new messages to existing array
   * @param chatMsg - new chat messages
   * @param contact - selected chat member
   */
  loadMessages(chatMsg: any | Array<any>, contact: any) {
    let newloadMessages = chatMsg.messages;

    // all messages has been loaded
    if (newloadMessages.length === 0) {
      ///no message

      if (!contact.messages)
        contact.messages = [];
      //   contact.messages.push({ type: "notification", content: contact.name + " added as project member", dateCreated: contact.dateCreated });
      //   this.groupByDate(contact, 1, 1);
      // }
    } else {

      let newMsgLength = newloadMessages.length;
      contact.messages.unshift(
        ...this.reversArray(newloadMessages)
      );
      for (let index = newMsgLength; index < contact.messages.length; index++) {
        if ((new Date(contact.messages[index - 1].dateCreated).setHours(0, 0, 0, 0)) == (new Date(contact.messages[index].dateCreated).setHours(0, 0, 0, 0))) {

          contact.messages[index].divider = { divider: false, value: null };
          break;
        }
      }
      this.groupByDate(contact, 1, newMsgLength);
    }
  }

  /**
   * Update unread count of messages
   * @param data : payload object
   * @returns {Observable<any>}
   */
  updateUnreadMessage(data) {
    return this.baseService.patch(this.apiUrl + "/chats/updateSeen", true, data);
  }

  /**
   * Update User Unread locally
   * @param chatObj
   */
  updateUnreadLocal(chatObj, loggedInUserId) {

    let seenObjFound = false;

    for (var seenObj of chatObj.seen) {
      if (seenObj.userId === loggedInUserId) {
        seenObjFound = true;
        seenObj.messageId = chatObj.lastMessage._id;
      }
    }

    if (!seenObjFound) {
      chatObj.seen.push({
        messageId: chatObj.lastMessage._id,
        userId: loggedInUserId,
      });
    }
    chatObj.unreadMessageCount = 0;
  }

  /**
     * Download an attached file of a Submittal
     * @param mediaId : string - media id
     * @returns {Observable<any>}
     */
  downloadSubmittalFile(mediaId) {
    return this._httpClient.get(environment.basePath + "/media/submittal/" + mediaId, { responseType: 'blob' });
  }

}
