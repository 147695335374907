import { Injectable } from '@angular/core';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';

@Injectable({
  providedIn: 'root'
})
export class StakeholderNotificationsService {

  showPanel: boolean = false;
  apiRootUrl = environment.basePath;

  constructor(
    private baseService: BaseService
  ) { }

  /**
   * Get notifications
   * @param limit : number
   * @param skip : number
   * @returns {Observable<any>}
   */
  getNotifications(limit, skip) {
    return this.baseService.get(this.apiRootUrl + `/users/notifications?limit=${limit}&skip=${skip}`, true);
  }

  /**
   * Get notifications count
   * @returns {Observable<any>}
   */
  getNotificationCount() {
    return this.baseService.get(this.apiRootUrl + "/users/notifications-count", true);
  }

  /**
   * Mark all notification as seen
   * @param ids 
   * @returns {Observable<any>}
   */
  markAllAsSeen(ids: any) {
    return this.baseService.patch(this.apiRootUrl + "/notifications/seen", true, ids);
  }

  /**
   * Delete all notifications
   * @param obj 
   * @returns {Observable<any>}
   */
  deleteAll(obj: any) {
    return this.baseService.delete(this.apiRootUrl + "/notifications", true, obj);
  }

  /**
   * Mark a notification as seen
   * @param id 
   * @returns {Observable<any>}
   */
  updateNotification(id: string) {
    return this.baseService.patch(this.apiRootUrl + "/notifications/seen/" + id, true, null);
  }
}
