import { Component, OnDestroy, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, AfterViewChecked, ChangeDetectorRef, AfterContentChecked, ViewChild, Renderer2, RendererStyleFlags2, AfterViewInit } from "@angular/core";
import { forkJoin, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";

//socketIo
import * as io from "socket.io-client";

//fuse
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { navigation } from "app/navigation/navigation";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

//Subtraid
import { AuthService } from "../../../main/services/auth.service";
import { ActivatedRoute, ActivationEnd, NavigationEnd, ParamMap, Router } from "@angular/router";
import { environment } from "../../../../environments/environment.uat";
import { MatDialog, MatSnackBar } from '@angular/material';
import { NotificationService } from 'app/main/pages/dashboard/notifications/notification.service';
import { CommunicationService, FileManagerAccount } from "app/main/services/communication.service";
import { ExportDataComponent } from "./export-data/export-data.component";

import { CreateProjectComponent } from "./create-project/create-project.component";
import { ProjectsService } from "app/main/services/projects.service";
import { Location } from "@angular/common";
import { DialogDeleteComponent } from "app/main/pages/dashboard/scrumboard/dialog-delete/dialog-delete.component";
import { Project } from "app/main/pages/dashboard/scrumboard/project.model";
import { DuplicateBoardComponent } from "app/main/pages/dashboard/scrumboard/project-board/duplicate-board/duplicate-board.component";
import { CreateBoardTemplateComponent } from "app/main/pages/dashboard/scrumboard/project-board/create-board-template/create-board-template.component";
import { ProjectPermissionSettings } from "app/main/pages/dashboard/scrumboard/permissions";

// import { AddEventComponent } from "app/main/pages/dashboard/calendar/add-event/add-event.component";
//C:\Angular\Subtraid-Angular\src\app\main\pages\dashboard\calendar\calendar.service.ts
import { CalendarService } from 'app/main/pages/dashboard/calendar/calendar.service';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay } from 'angular-calendar';
import { CalendarEventModel } from 'app/main/pages/dashboard/calendar/event.model';
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { FileManagementService } from "app/main/pages/dashboard/file-management/file-management.service";
import { JoyrideService, JoyrideStepService } from "ngx-joyride";
import { DecodedToken } from "app/main/models/decoded-token";
import { StakeholderNotificationsService } from "app/main/pages/stakeholder-users/pages/stakeholder-notifications/stakeholder-notifications.service";



//menuIcon

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userName: string;
    basePath = environment.basePath;
    notificationCounts = 0;
    userImg: String;
    subtraidSubscription: any;
    menuIcons: any[] = [];
    show = false;
    dialogRef: any;
    isScrumBoard = false;
    board: Project;
    parentProject: any;
    // boardId = false;


    project = [];
    employee = [];
    projects: any[];
    employees: any[];
    actions: CalendarEventAction[];
    events: CalendarEvent[];
    eventsCopy: CalendarEvent[];
    // cardId: string = null;
    isIssue = false;
    projsetting: ProjectPermissionSettings;
    titleBreakPoint = false;

    searchKey: string;
    //socket Io connect
    private socket = io(this.basePath, {
        transports: ['websocket'],
        upgrade: false,
    });
    // Private
    private _unsubscribeAll: Subject<any>;
    refresh: Subject<any> = new Subject();

    isSubtraidEmployee = false;
    isCAdmin = false;
    backNavLink: string = 'dashboard/projects';
    isFileManager = false;
    fmAccountName: FileManagerAccount;
    demoPage = 'projects';
    stylesMap: Map<any, Node> = new Map();

    currentUrl: string = "";
    loggedInUserCompanyId: string = null;
    isStakeholder: boolean = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _fuseNavigationService: FuseNavigationService,
        private AuthService: AuthService,
        private myRouter: Router,
        private http: HttpClient,
        public _matDialog: MatDialog,
        private notificationService: NotificationService,
        private cdRef: ChangeDetectorRef,
        private commService: CommunicationService,
        private projectService: ProjectsService,
        private _location: Location,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private _calendarService: CalendarService,
        private _matSnackBar: MatSnackBar,
        private breakpointObsv: BreakpointObserver,
        private fileManagerService: FileManagementService,
        private readonly joyrideService: JoyrideService,
        private joyrideStepService: JoyrideStepService,


        private renderer: Renderer2,
        private stakeholderNotificationService: StakeholderNotificationsService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();


        this.breakpointObsv
            .observe(['(max-width: 650px)'])   ///770px with 80:20
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    //less than 650          
                    this.titleBreakPoint = true;
                } else {
                    //more than 650        
                    this.titleBreakPoint = false;
                }
            });
        //icon

        //initalize toolbar for each module/pages
        this.myRouter.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(event => {

                let temp = [];
                this.currentUrl = event['url'];
                temp = event['url'].split('/');

                
                if (temp.includes('subtraid-employee')) {
                    this.isSubtraidEmployee = true;
                    this.isStakeholder = false;
                    this.isScrumBoard = false;
                }
                else if (temp.includes('stakeholder-users')) {

                    this.isSubtraidEmployee = false;
                    this.isStakeholder = true;
                    this.isScrumBoard = false;

                    this.backNavLink = "";
                    if (temp.includes('project') && (temp.includes('pcr') || temp.includes('submittal') || temp.includes('rfi') || temp.includes('updates') || temp.includes('chat') || temp.includes('billing')))
                        this.backNavLink = "stakeholder-users/projects/project/" + temp[4];

                    if (temp.includes('projects')) {
                        this.demoPage = "shprojects";
                        if (temp.includes('project')) {
                            this.demoPage = "shDashboard";
                            if (temp.includes('updates')) this.demoPage = "shLatest";
                            else if (temp.includes('pcr')) this.demoPage = "shPcr";
                            else if (temp.includes('rfi')) this.demoPage = "shRfi";
                            else if (temp.includes('submittal')) this.demoPage = "shSubmittal";
                            else if (temp.includes('chat')) this.demoPage = "shChat";
                            else if (temp.includes('billing')) this.demoPage = "shBilling";
                        }
                    }
                    else if (temp.includes('profile')) this.demoPage = "shProfile";
                }
                else {
                    this.isSubtraidEmployee = false;
                    this.isStakeholder = false;

                    //check if its a project module
                    if (temp.includes('boards') && temp.includes('projects')) {
                        let len = temp.indexOf('boards') + 1;

                        // if (!(len < temp.length))
                        //     this.boardId = null;
                        if (len < temp.length)
                            this.isScrumBoard = true;
                        else
                            this.isScrumBoard = false;

                        //set back navigation link for main pages
                        if (temp.length == 7) {

                            if (temp[6].includes('card'))
                                this.backNavLink = 'dashboard/projects/boards/' + temp[4] + '/' + temp[6].split('=')[1];
                            else
                                this.backNavLink = 'dashboard/projects/boards/' + temp[4];
                        }
                        else if (temp.length == 6) {
                            this.backNavLink = 'dashboard/projects/boards/' + temp[4];
                        }
                        else {

                            this.backNavLink = 'dashboard/projects';
                        }


                    }
                    else
                        this.isScrumBoard = false;
                    if (temp.includes('issues')) this.isIssue = true; else this.isIssue = false;

                    if (temp.includes('home') || (temp.length == 2 && (temp.includes("") && temp.includes('dashboard')))) {
                        //on home page clear toolbar variables

                        sessionStorage.removeItem('selectedProject');
                    }
                    if (temp.includes('file-manager') && (temp.includes('dropbox') || temp.includes('googledrive') || temp.includes('onedrive'))) {

                        this.isFileManager = true;
                    }
                    else this.isFileManager = false;

                    //set variable for onboarding or Take a Tour feature
                    if (temp.includes('projects') && temp.includes('boards')) {
                        this.demoPage = 'projects';
                        let len = temp.indexOf('boards') + 1;

                        if (len < temp.length)
                            this.demoPage = 'project-detail';

                        if (temp.includes('issues'))
                            this.demoPage = 'issue';
                        else if (temp.length == (temp.indexOf('boards') + 3))
                            this.demoPage = "card-detail";
                        else if (temp.includes('pcr'))
                            this.demoPage = "pcr";
                        else if (temp.includes('rfi'))
                            this.demoPage = "rfi";
                        else if (temp.includes('submittals'))
                            this.demoPage = "submittals";

                    }
                    else if (temp.includes('contacts')) this.demoPage = 'contacts';
                    else if (temp.includes('chat')) this.demoPage = 'chat';
                    else if (temp.includes('company')) this.demoPage = 'company';
                    else if (temp.includes('timesheets')) this.demoPage = 'timesheets';
                    else if (temp.includes('calendar')) this.demoPage = 'calendar';
                    else if (temp.includes('platform-improvement') && !(temp.includes('subtraid-employee'))) this.demoPage = 'platform-improvement';
                    else if (temp.includes('profile')) this.demoPage = 'profile';
                    else if (temp.includes('gantt-chart')) this.demoPage = 'gantt-chart';

                    // console.log('demo=', this.demoPage)
                }

            });
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.isSubtraidEmployee = this.AuthService.isSubtraidEmployee();



        // sessionStorage.setItem("pg", JSON.stringify(this.menuIcons));
        // sessionStorage.setItem("cp", "plumbing");
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        //aubacribe to subtraidStorage services

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });



        // Subscribe to the user changes
        this.AuthService.user
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {

                this.userImg = user.img || 'undefined';
                this.userName = user.name;


                // this.loggedInUserCompanyId = user.accountType == 'user' ? user.employerCompany._id : user._id;
            });

        let userInfo = this.AuthService.getBarereToken();

        if (userInfo) {
            if (userInfo.accountType == 'user')
                this.loggedInUserCompanyId = userInfo.employer ? userInfo.employer : null;
            else
                this.loggedInUserCompanyId = userInfo._id;
        }

        // // for reconnection
        // this.socket.on("connect", () => {
        //     if (this.AuthService.isLogin()) {
        //         //socket Io listen to new notifications
        //         this.socket.emit("userId", {
        //             id: userInfo._id,
        //         });
        //     }
        // });

        //set subtraid's customer/target user topbar
        if (!this.AuthService.isSubtraidEmployee() && !this.AuthService.isStakeholderUser()) {
            // if its customer login
            if (this.AuthService.isLogin()) {
                this.isCAdmin = userInfo.isAdmin;

                //socket Io listen to new notifications
                this.socket.emit("userId", {
                    id: userInfo._id,
                });

                this.socket.on("fetchNotificationCounts", (data) => {

                    if (this.AuthService.isLogin())
                        this.getNotificationCount();
                });

                this.socket.on("updateChatUnreadCount", (data) => {
                    this.updateSideBarChatBadge(data.chatsUnreadCount);
                });
            }

            this.commService.showButton
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(display => this.show = display);

            this.projectService.projectBoard
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(res => {

                    this.board = res;
                    if (this.board && this.loggedInUserCompanyId == this.board.owner._id)
                        this.parentProject = null;
                    else {
                        if (this.board && this.board.parentProject) {
                            this.parentProject = this.board.parentProject;

                        }
                        else
                            this.parentProject = null;
                    }

                });

            this.projectService.projectSetting
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(res => {

                    this.projsetting = res;

                });

            // this.activatedRoute.queryParamMap
            //     .pipe(takeUntil(this._unsubscribeAll))
            //     .subscribe((params) => {

            //         if (params.get('parent'))
            //             this.parentProject = params.get('parent');
            //         else
            //             this.parentProject = null;
            //     });

            this.commService.fileManagerAccount
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(res => {

                    this.fmAccountName = res;

                });
        }
        else if (this.AuthService.isSubtraidEmployee()) {
            ///subtraid employee topbar
        }
        else if (this.AuthService.isStakeholderUser()) {
            ///stakeholder or external users topbar

            if (this.AuthService.isLogin()) {
                //socket Io listen to new notifications
                this.socket.emit("userId", {
                    id: userInfo._id,
                });

                this.socket.on("fetchNotificationCounts", (data) => {

                    if (this.AuthService.isLogin())
                        this.getNotificationCount();
                });

                this.socket.on("updateChatUnreadCount", (data) => {
                    this.updateSideBarChatBadge(data.chatsUnreadCount);
                });
            }
        }
    }

    /**
     * Redirects to back navigation
     */
    goBack() {
        if (this.isScrumBoard) {
            // if(this.backNavLink=="dashboard/projects") this.projectService.refreshProject(null);
            if (this.parentProject == null)
                this.myRouter.navigate([this.backNavLink]);     //this.myRouter.navigate(['dashboard/projects/boards']);
            else {

                if (this.currentUrl.split('/').length == 5) {
                    this.myRouter.navigate(['dashboard/projects/boards/' + this.parentProject._id]).then(() => {
                        window.location.reload();
                    });
                }
                else
                    this.myRouter.navigate([this.backNavLink]);
            }
        }
        else if (this.isFileManager)
            this.myRouter.navigate(['dashboard/file-manager']);
        else if (this.isStakeholder) {
            if (this.backNavLink == "")
                this.myRouter.navigate(['stakeholder-users', 'projects']);
            else this.myRouter.navigate([this.backNavLink]);
        }

        // this.myRouter.navigate([this.backNavLink]);
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update SideBar Chat Badge
     * @param number
     */

    updateSideBarChatBadge(number) {
        // Update the badge title
        this._fuseNavigationService.updateNavigationItem("chat", {
            badge: {
                title: number,
                bg: "red",
                fg: "white",
            },
        });
        this._fuseNavigationService.updateNavigationItem("shchat", {
            badge: {
                title: number,
                bg: "red",
                fg: "white",
            },
        });
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    //Subtraid

    /**
     * @function
     *    calling the authserivce logout function to log user out and redirect to the login page
     * @param
     * @return
     */

    onLogOut() {
        this.AuthService.logOut();
    }

    /**
     * @function
     *    Navigatge to the profile page
     * @param
     * @return
     */

    goToProfile() {
        if (this.isSubtraidEmployee)
            this.myRouter.navigate(["subtraid-employee", "profile"]);
        if (this.AuthService.isStakeholderUser())
            this.myRouter.navigate(["stakeholder-users", "profile"]);
        else
            this.myRouter.navigate(["dashboard", "profile"]);
    }

    /**
     * @function
     *    Navigate to the notifications page
     * @param
     * @return
     */

    goToNotifications() {
        if (this.AuthService.isStakeholderUser())
            this.stakeholderNotificationService.showPanel = !this.stakeholderNotificationService.showPanel;
        else
            this.notificationService.showPanel = !this.notificationService.showPanel;

        //this.myRouter.navigate(["dashboard", "notifications"]);
    }

    /**
     * @function
     *    Get notification counts
     * @param
     * @return Number
     */

    getNotificationCount() {

        let endPoint = this.basePath + "/users/notifications-count";

        this.http.get(endPoint)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (data: any) => {
                    this.notificationCounts = data.counts;

                },
                (err) => {
                    console.log("Not able to retrive notification counts");
                }
            );
    }


    get toolbarName() {

        return sessionStorage.getItem('selectedProject');
        // return localStorage.getItem('selectedProject');
        //return sessionStorage.getItem('selectedProjectChatId');
    }
    get projectLocation() {
        return sessionStorage.getItem("selectedLocation")
    }
    get isProjectBoard() {
        return sessionStorage.getItem("isProjectBoard") == 'true' ? true : false;
    }

    /**
     * Redirects to Contact's Available users Tab
     */
    goToContacts() {
        // sessionStorage.setItem('selectedProject', 'Contacts');
        this.myRouter.navigate(['/dashboard/contacts/available']);
    }

    /**
     * Opens dialog box to Export timesheet data
     */
    exportdata() {
        this._matDialog.open(ExportDataComponent, {
            width: '50%'
        });
    }

    /**
     * Opens dialog box to create project
     */
    addProject() {
        this.dialogRef = this._matDialog.open(CreateProjectComponent, {
            minWidth: '60%',
            data: {
                templateInfo: null
            }
        });
        this.dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res)
                    this.myRouter.navigate(['dashboard/projects/boards/' + res.result.board._id]); // redirects to project's detail board
            });

    }
    setEvents(): void {
        this.events = this._calendarService.events.events.map(i => {
            i.id = i._id;
            i.start = new Date(i.start);
            i.end = new Date(i.end);
            i.actions = this.actions;
            return new CalendarEventModel(i);
        });
    }
    refreshData() {
        this._calendarService.getUserEvents().then(() => {
            this.setEvents();
        });
        this.refresh.next(true);
    }

    /**
     * Deletes a project's board
     */
    deleteBoard() {
        this.dialogRef = this._matDialog.open(DialogDeleteComponent, {
            data: {
                message: "Do you want to delete this board?"
            }
        });
        this.dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res) {
                    this.commService.showHideLoader('Deleting Board...', true);
                    let observables = [];
                    this.board.lists.forEach(list => {
                        observables.push(this.projectService.removeList({
                            projectId: this.board._id,
                            listId: list._id
                        })
                            .pipe(takeUntil(this._unsubscribeAll)));
                    });

                    forkJoin(observables).subscribe(resp => {


                        this.projectService.getProject(this.board._id, 'toolbar')
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(res => {
                                this.projectService.refreshProject(res.board);

                                this.commService.showHideLoader('', false);
                                this._matSnackBar.open('Board deleted successfully');
                            }, (err) => {
                                this.commService.showHideLoader('', false);
                            });

                    });

                }
            });

    }

    /**
     * Duplicate a project's board
     */
    duplicateBoard() {

        this.dialogRef = this._matDialog.open(DuplicateBoardComponent, {
            minWidth: '50%',
            data: this.board
        });
        this.dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res) {
                    this.snackBar.open('Board has been duplicated successfully');
                }
            });
    }

    /**
     * Create a template of a project's board
     */
    createTemplate() {
        this.dialogRef = this._matDialog.open(CreateBoardTemplateComponent, {
            data: this.board
        });
        this.dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res) {
                    this.snackBar.open('Template created successfully');
                }
            });
    }

    /**
     * After timesheet setting change, display a message
     */
    updateTimesheetSetting() {
        this.snackBar.open('Timesheet Setting updated successfully');
    }

    /**
     * Logout from a file manager's provider account
     */
    logotFromFM() {
        let fmtype = this.fmAccountName.file_manager;
        this.fileManagerService.logoutFM(fmtype)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(resp => {

                let tokentype = (fmtype == 'onedrive' ? 'odToken' : (fmtype == 'dropbox' ? 'dbToken' : 'gdToken'));
                localStorage.removeItem(tokentype);
                if (fmtype == 'onedrive')
                    window.open(resp.signOutUrl, '_top');
                else
                    this.myRouter.navigate(['dashboard/file-manager']);
            });
    }


    /**
     * Start a page's tour or onboarding
     */
    startTour() {

        if (this.isStakeholder) { // if stakeholders
            if (this.demoPage == 'shprojects') {
                if (document.querySelector(`[ng-reflect-name='shproject2']`) != null)
                    this.preSetStep('shproject2', 353, document.querySelector(`[ng-reflect-name='shproject2']`).getBoundingClientRect());

                this.joyrideService.startTour(
                    { steps: ['shproject1', 'shproject2'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name != 'shproject2') {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();
                        else if (currentStep.name == 'shproject2') {
                            let targetPos = currentStepElement.getBoundingClientRect();
                            this.showStepAtTopLeft(currentStep.name, targetPos);
                        }
                    }, () => {
                        this.removeStyle('shproject2');
                    });
            }
            else if (this.demoPage == "shDashboard") {
                this.joyrideService.startTour(
                    { steps: ['shDash1', 'shDash2', 'shDash3', 'shDash4', 'shDash5', 'shDash6', 'shDash7', 'shDash8', 'shDash9', 'shDash10', 'shDash11', 'shDash12', 'shDash13'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );

                        if (currentStepElement == null)
                            this.joyrideStepService.next();
                    });
            }
            else if (this.demoPage == "shPcr") {
                this.joyrideService.startTour(
                    { steps: ['shPcr11', 'shPcr12', 'shPcr21', 'shPcr22', 'shPcr23', 'shPcr24', 'shPcr3', 'shPcr4'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );

                        if (currentStepElement == null)
                            this.joyrideStepService.next();
                    });
            }
            else if (this.demoPage == "shRfi") {
                this.joyrideService.startTour(
                    { steps: ['shRfi11', 'shRfi12', 'shRfi21', 'shRfi22', 'shRfi23', 'shRfi24', 'shRfi13', 'shRfi14', 'shRfi15'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {

                        if (currentStep.name == 'shRfi12') {
                            setTimeout(() => {
                                const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
                                let targetPos = document.querySelector('.add-rfi-btn').getBoundingClientRect();
                                this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);

                            }, 100);
                        }
                        else if (currentStep.name == 'shRfi13') {
                            setTimeout(() => {
                                const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
                                let targetPos = document.querySelector('#rfiIssuedTab').getBoundingClientRect();
                                this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);

                            }, 100);

                        }
                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == "shSubmittal") {
                this.joyrideService.startTour(
                    { steps: ['shSubmittal11', 'shSubmittal12', 'shSubmittal13', 'shSubmittal16'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {

                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );

                        if (currentStepElement == null)
                            this.joyrideStepService.next();

                    });
            }

        }
        else {   //if subtraid's target users
            if (this.demoPage == 'projects') {

                if (document.querySelector(`[ng-reflect-name='project5']`) != null)
                    this.preSetStep('project5', 353, document.querySelector(`[ng-reflect-name='project5']`).getBoundingClientRect());

                this.joyrideService.startTour(
                    { steps: ['project1', 'project6', 'project2', 'project3', 'project4', 'project5'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name != 'project5') {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();
                        else if (currentStep.name == 'project5') {
                            let targetPos = currentStepElement.getBoundingClientRect();
                            this.showStepAtBottom(currentStep.name, targetPos);
                        }
                        else {

                        }
                    }, () => {
                        this.removeStyle('project5');
                    });
            }
            else if (this.demoPage == 'project-detail') {
                this.projectService.startProjectDetailTour(true);

                if (this.breakpointObsv.isMatched('(min-width: 600px)')) {
                    this.joyrideService.startTour(
                        { steps: ['projectd1', 'projectd2', 'projectd3', 'projectd4', 'projectd5', 'projectd6', 'projectd7', 'projectd8', 'projectd24', 'projectd9', 'projectd11', 'projectd10', 'projectd12', 'projectd13', 'projectd14', 'projectd25', 'projectd15', 'projectd16', 'projectd17', 'projectd18', 'projectd19', 'projectd20', 'projectd21', 'projectd22', 'projectd23'], themeColor: '#114684', showCounter: false, waitingTime: 500 }
                    )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(currentStep => {
                            let dataBound = ['projectd3', 'projectd4', 'projectd5', 'projectd6', 'projectd7', 'projectd8', 'projectd11', 'projectd19', 'projectd20'];
                            let currentStepElement;
                            if (dataBound.includes(currentStep.name)) {
                                currentStepElement = document.querySelector(
                                    `[ng-reflect-name='${currentStep.name}']`
                                );
                            }
                            else {
                                currentStepElement = document.querySelector(
                                    `[joyridestep='${currentStep.name}']`
                                );
                            }

                            if (currentStepElement == null)
                                this.joyrideStepService.next();

                        });
                }
                else {
                    this.preSetStep('projectd2', 265, document.querySelector(`[ng-reflect-name='projectd2']`).getBoundingClientRect());
                    this.preSetStep('projectd3', 360, document.querySelector(`[ng-reflect-name='projectd3']`).getBoundingClientRect());
                    this.preSetStep('projectd4', 213, document.querySelector(`[ng-reflect-name='projectd4']`).getBoundingClientRect());
                    this.preSetStep('projectd5', 213, document.querySelector(`[ng-reflect-name='projectd5']`).getBoundingClientRect());
                    this.preSetStep('projectd6', 213, document.querySelector(`[ng-reflect-name='projectd6']`).getBoundingClientRect());
                    this.preSetStep('projectd7', 213, document.querySelector(`[ng-reflect-name='projectd7']`).getBoundingClientRect());
                    this.preSetStep('projectd8', 213, document.querySelector(`[ng-reflect-name='projectd8']`).getBoundingClientRect());
                    this.preSetStep('projectd24', 213, document.querySelector(`[ng-reflect-name='projectd24']`).getBoundingClientRect());

                    this.joyrideService.startTour(
                        { steps: ['projectdR1', 'projectd2', 'projectd3', 'projectd4', 'projectd5', 'projectd6', 'projectd7', 'projectd8', 'projectd24', 'projectdR9', 'projectd11', 'projectd10', 'projectdR12', 'projectd13', 'projectd14', 'projectd25', 'projectdR15', 'projectd16', 'projectd17', 'projectdR18', 'projectd19', 'projectd20', 'projectdR23', 'projectd21', 'projectd22'], themeColor: '#114684', showCounter: false, waitingTime: 500 }
                    )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(currentStep => {
                            let dataBound = ['projectd2', 'projectd3', 'projectd4', 'projectd5', 'projectd6', 'projectd7', 'projectd8', 'projectd24', 'projectd11', 'projectd19', 'projectd20'];
                            let currentStepElement;
                            if (dataBound.includes(currentStep.name)) {
                                currentStepElement = document.querySelector(
                                    `[ng-reflect-name='${currentStep.name}']`
                                );
                            }
                            else {
                                currentStepElement = document.querySelector(
                                    `[joyridestep='${currentStep.name}']`
                                );
                            }

                            if (currentStepElement == null)
                                this.joyrideStepService.next();
                            else if (dataBound.includes(currentStep.name)) {
                                let targetPos = currentStepElement.getBoundingClientRect();
                                this.showStepAtBottom(currentStep.name, targetPos);
                            }
                            else {

                            }
                        }, () => {
                            this.removeStyle('projectd2');
                            this.removeStyle('projectd3');
                            this.removeStyle('projectd4');
                            this.removeStyle('projectd5');
                            this.removeStyle('projectd6');
                            this.removeStyle('projectd7');
                            this.removeStyle('projectd8');
                            this.removeStyle('projectd24');
                        });
                }
            }
            else if (this.demoPage == 'card-detail') {

                this.joyrideService.startTour(
                    { steps: ['card1', 'card2', 'card3', 'card4', 'card5', 'card6', 'card7', 'card8', 'card9', 'card10', 'card11', 'card12', 'card13', 'card14', 'card15', 'card16', 'card17', 'card18', 'card19'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name == 'card7' || currentStep.name == 'card11' || currentStep.name == 'card15') {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'issue') {

                if (this.breakpointObsv.isMatched('(min-width: 960px)')) {

                    this.preSetStep('issue2', 215, document.querySelector(`[ng-reflect-name='issue2']`).getBoundingClientRect());
                    this.preSetStep('issue3', 370, document.querySelector(`[ng-reflect-name='issue3']`).getBoundingClientRect());
                }
                else {
                    this.projectService.targetDimension
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(res => {
                            let arr = [];
                            arr = res;
                            // let targetPos = arr.filter(obj => obj.id == 'issue2')[0].target;
                            this.preSetStep('issue2', 215, arr.filter(obj => obj.id == 'issue2')[0].target);
                            this.preSetStep('issue3', 370, arr.filter(obj => obj.id == 'issue3')[0].target);

                        });
                }


                this.joyrideService.startTour(
                    { steps: ['issue1', 'issue2', 'issue3'], themeColor: '#114684', showCounter: false, logsEnabled: true }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentstep => {


                        const currentStepElement = document.querySelector(
                            `[ng-reflect-name='${currentstep.name}']`
                        );

                        if (currentStepElement == null) {
                            // skip this step
                            this.joyrideStepService.next();
                        }
                        else if (currentstep.name == 'issue2' || currentstep.name == 'issue3') {

                            //assuming step is displayed at bottom of target element

                            if (this.breakpointObsv.isMatched('(min-width: 960px)')) {
                                let targetPos = currentStepElement.getBoundingClientRect();
                                this.showStepAtBottom(currentstep.name, targetPos);
                            }
                            else {
                                this.projectService.targetDimension
                                    .pipe(takeUntil(this._unsubscribeAll))
                                    .subscribe(res => {
                                        let arr = [];
                                        arr = res;
                                        let targetPos = arr.filter(obj => obj.id == currentstep.name)[0].target;
                                        this.showStepAtBottom(currentstep.name, targetPos);
                                    });
                            }
                        }
                        else {

                        }
                    }, (err) => {
                        console.log(err);
                    }, () => {
                        this.removeStyle('issue2');
                        this.removeStyle('issue3');
                    });



            }
            else if (this.demoPage == 'contacts') {
                this.joyrideService.startTour(
                    { steps: ['contact1', 'contact2', 'contact3', 'contact4', 'contact5', 'contact6', 'contact7'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name == 'contact3' || currentStep.name == 'contact5') {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'chat') {
                this.commService.startChatTour(true);

                // if (this.breakpointObsv.isMatched('(max-width: 959px)')) {

                //     this.preSetStep('chat1', 377, document.querySelector(`[ng-reflect-name='chat1']`).getBoundingClientRect());
                //     if(document.querySelector(`[ng-reflect-name='chat2']`) != null)
                //     this.preSetStep('chat2', 370, document.querySelector(`[ng-reflect-name='chat2']`).getBoundingClientRect());
                // }
                this.joyrideService.startTour(
                    { steps: ['chat1', 'chat2', 'chat3', 'chat4', 'chat5'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name == 'chat4') {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'platform-improvement') {
                this.joyrideService.startTour(
                    { steps: ['impr1', 'impr2'], themeColor: '#114684', showCounter: false }
                );
            }
            else if (this.demoPage == 'company') {
                this.commService.startCompanyTour(true);
                this.joyrideService.startTour(
                    { steps: ['company1', 'company2', 'company3', 'company4', 'company5', 'company6', 'company7', 'company8', 'company9'], themeColor: '#114684', showCounter: false, waitingTime: 500 }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name == 'company2') {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'timesheets') {
                this.commService.startTimesheetTour(true);
                this.joyrideService.startTour(
                    { steps: ['timesheet1', 'timesheet2', 'timesheet3', 'timesheet4'], themeColor: '#114684', showCounter: false, waitingTime: 500 }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;
                        if (currentStep.name == 'timesheet3') {
                            currentStepElement = document.querySelector(
                                `[ng-reflect-name='${currentStep.name}']`
                            );
                        }
                        else {
                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );
                        }

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'calendar') {

                this.joyrideService.startTour(
                    { steps: ['calendar1', 'calendar2', 'calendar3'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );


                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'profile') {
                let authuser: DecodedToken = this.AuthService.getBarereToken();
                if (authuser.accountType == 'company') {
                    this.commService.startProfileTour(true);
                    this.joyrideService.startTour(
                        { steps: ['profileC1', 'profileC2', 'profileC3', 'profileC4', 'profileC5'], themeColor: '#114684', showCounter: false, waitingTime: 500 }
                    )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(currentStep => {
                            let currentStepElement;

                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );


                            if (currentStepElement == null)
                                this.joyrideStepService.next();
                        });
                }
                else if (authuser.accountType == 'user') {
                    this.commService.startProfileTour(true);
                    this.joyrideService.startTour(
                        { steps: ['profileU1', 'profileU2', 'profileU3', 'profileU4', 'profileU5', 'profileU6'], themeColor: '#114684', showCounter: false, waitingTime: 500 }
                    )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(currentStep => {
                            let currentStepElement;

                            currentStepElement = document.querySelector(
                                `[joyridestep='${currentStep.name}']`
                            );


                            if (currentStepElement == null)
                                this.joyrideStepService.next();
                        });
                }
            }
            else if (this.demoPage == 'gantt-chart') {
                this.joyrideService.startTour(
                    { steps: ['ganttch1', 'ganttch2', 'ganttch3', 'ganttch4', 'ganttch5', 'ganttch6', 'ganttch7', 'ganttch71', 'ganttch8', 'ganttch9', 'ganttch10', 'ganttch11'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        if (currentStep.name == 'ganttch7') {
                            let rightCell = document.querySelector('.zt-gantt-right-cell');
                            rightCell.scrollLeft = 0;
                            rightCell.scrollTop = 0;
                            setTimeout(() => {
                                this.renderer.setStyle(document.querySelector('.zt-gantt-link-control div'), 'display', 'block', RendererStyleFlags2.Important);
                                let targetPos = document.querySelector('.zt-gantt-left-point').getBoundingClientRect();

                                this.showStepAtBottom('ganttch7', targetPos)
                            }, 100);

                        }
                        else if (currentStep.name == 'ganttch71') {
                            let target = document.querySelector('.zt-gantt-bar-task.zt-gantt-bar-parent-task');

                            let targetPos = target.getBoundingClientRect();
                            this.showStepAtTopLeft('ganttch71', targetPos);
                        }

                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );


                        if (currentStepElement == null)
                            this.joyrideStepService.next();
                    }, err => {
                        console.log(err);
                    });
            }
            else if (this.demoPage == 'pcr') {
                this.joyrideService.startTour(
                    { steps: ['pcr11', 'pcr12', 'pcr21', 'pcr22', 'pcr23', 'pcr24', 'pcr3', 'pcr4'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {
                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );


                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'rfi') {
                this.joyrideService.startTour(
                    { steps: ['rfi11', 'rfi12', 'rfi21', 'rfi22', 'rfi23', 'rfi24', 'rfi13', 'rfi14', 'rfi15'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {

                        if (currentStep.name == 'rfi12') {
                            setTimeout(() => {
                                const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
                                let targetPos = document.querySelector('.add-rfi-btn').getBoundingClientRect();
                                this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);

                            }, 100);
                        }
                        else if (currentStep.name == 'rfi13') {
                            setTimeout(() => {
                                const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
                                let targetPos = document.querySelector('#rfiIssuedTab').getBoundingClientRect();
                                this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);

                            }, 100);

                        }
                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );

                        if (currentStepElement == null)
                            this.joyrideStepService.next();


                    });
            }
            else if (this.demoPage == 'submittals') {
                this.joyrideService.startTour(
                    { steps: ['submittal11', 'submittal12', 'submittal13', 'submittal14', 'submittal21', 'submittal22', 'submittal23', 'submittal15', 'submittal16'], themeColor: '#114684', showCounter: false }
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentStep => {


                        let currentStepElement;

                        currentStepElement = document.querySelector(
                            `[joyridestep='${currentStep.name}']`
                        );

                        if (currentStepElement == null)
                            this.joyrideStepService.next();
                        else if (currentStep.name == 'submittal14') {

                            setTimeout(() => {
                                const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
                                const backdropLeft = document.getElementsByClassName('joyride-backdrop backdrop-left')[0];
                                let targetPos = document.querySelector('.add-submittal-btn').getBoundingClientRect();
                                const stepC = document.getElementById('joyride-step-submittal14');
                                let stepPos = stepC.getBoundingClientRect();

                                let stepleft = targetPos.width / 2 - stepPos.width / 2 + targetPos.left - 2;

                                this.renderer.setStyle(stepC, 'left', (stepleft < 0 ? 2 : stepleft) + 'px', RendererStyleFlags2.Important);
                                this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);
                                this.renderer.setStyle(backdropLeft, 'width', targetPos.left + 'px', RendererStyleFlags2.Important);

                            }, 500);
                        }
                        else if (currentStep.name == 'submittal15') {
                            setTimeout(() => {
                                const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
                                let targetPos = document.querySelector('#submittalIssuedTab').getBoundingClientRect();
                                this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);
                            }, 100);

                        }
                    });
            }
        }

    }


    /**
     * Set onboarding step box's position on screen
     * @param stepName : string
     * @param stepWidth : number
     * @param targetPos : any
     */
    preSetStep(stepName: string, stepWidth, targetPos) {
        //this function minimizes the movement of step box position change from top-left


        // const targetElement = document.querySelector(
        //     `[ng-reflect-name='${stepName}']`
        // );
        // console.log('stepwidth=',targetPos);

        let stepStyle: HTMLElement = this.renderer.createElement('style');
        stepStyle.innerHTML = `#joyride-step-${stepName} { top: ${targetPos.top + 15 + targetPos.height}px !important; left: ${targetPos.width / 2 - stepWidth / 2 + targetPos.left - 2}px !important; \n.joyride-step__arrow{ left : ${stepWidth / 2 - 8}px !important; } }`;

        this.stylesMap.set('joyride-step-' + stepName, stepStyle);
        this.renderer.appendChild(document.getElementsByTagName('head')[0], stepStyle);
    }

    /**
     * Show onboarding step box at bottom of the target element
     * @param stepname : string
     * @param targetPos : number
     */
    showStepAtBottom(stepname: string, targetPos) {   //assuming all step is displayed at bottom of target element

        const stepC = document.getElementById('joyride-step-' + stepname);
        let stepPos = stepC.getBoundingClientRect();

        const arrow = document.getElementsByClassName('joyride-step__arrow')[0];

        const backtarget = document.getElementsByClassName('backdrop-target')[0];
        const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
        const backdropLeft = document.getElementsByClassName('joyride-backdrop backdrop-left')[0];
        const backdropMiddle = document.getElementsByClassName('backdrop-middle-container')[0];
        setTimeout(() => {

            let stepleft = targetPos.width / 2 - stepPos.width / 2 + targetPos.left - 2;

            this.renderer.setStyle(stepC, 'top', targetPos.top + 15 + targetPos.height + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(stepC, 'left', (stepleft < 0 ? 2 : stepleft) + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(arrow, 'left', stepPos.width / 2 - 8 + 'px', RendererStyleFlags2.Important);

            this.renderer.setStyle(backtarget, 'width', targetPos.width + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(backdropLeft, 'width', targetPos.left + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(backdropMiddle, 'height', targetPos.height + 'px', RendererStyleFlags2.Important);

            this.removeStyle(stepname);

        }, 100);
    }

    /**
     * Show onboarding step box at top-left of the target element
     * @param stepname : string
     * @param targetPos : number
     */
    showStepAtTopLeft(stepname: string, targetPos) {
        const stepC = document.getElementById('joyride-step-' + stepname);
        let stepPos = stepC.getBoundingClientRect();
        const stepContainer = document.getElementsByClassName('joyride-step__container')[0];

        const arrow = document.getElementsByClassName('joyride-step__arrow')[0];

        const backtarget = document.getElementsByClassName('backdrop-target')[0];
        const backdropTop = document.getElementsByClassName('joyride-backdrop backdrop-top')[0];
        const backdropLeft = document.getElementsByClassName('joyride-backdrop backdrop-left')[0];
        const backdropMiddle = document.getElementsByClassName('backdrop-middle-container')[0];


        setTimeout(() => {

            // let stepleft = targetPos.width / 2 - stepPos.width / 2 + targetPos.left - 2;

            this.renderer.setStyle(stepC, 'top', targetPos.top - stepContainer.getBoundingClientRect().height - 15 + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(stepC, 'left', targetPos.left + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(arrow, 'left', stepPos.width / 2 - 8 + 'px', RendererStyleFlags2.Important);

            this.renderer.setStyle(backtarget, 'width', targetPos.width + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(backdropTop, 'height', targetPos.top + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(backdropLeft, 'width', targetPos.left + 'px', RendererStyleFlags2.Important);
            this.renderer.setStyle(backdropMiddle, 'height', targetPos.height + 'px', RendererStyleFlags2.Important);

            this.removeStyle(stepname);

        }, 100);
    }

    /**
     * Remove a style from an onboarding step
     * @param stepname : string
     */
    removeStyle(stepname: string) {
        const styleEl = this.stylesMap.get('joyride-step-' + stepname);
        if (styleEl) {
            this.stylesMap.delete('joyride-step-' + stepname);
            document.getElementsByTagName('head')[0].removeChild(styleEl);
        }
    }

}
