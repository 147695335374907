
import { Injectable } from "@angular/core";
import { DecodedToken } from "../models/decoded-token";
import { SubtraidEmployeeDecodedToken } from "../pages/subtraid-employee/models/subtraid-employee-decoded-token";
import { AuthService } from "./auth.service";
import { StakeholderDecodedToken } from "../models/stakeholder-decoded-token";
import { StakeholderProjectsService } from "../pages/stakeholder-users/pages/stakeholder-projects/stakeholder-projects.service";

@Injectable({
    providedIn: "root",
})
export class PermissionService {



    constructor(
        private authService: AuthService,
        private stakeholderProjectService: StakeholderProjectsService
    ) {

    }

    /**
     * Check User's permission
     * @param val : string
     * @returns {Boolean}
     */
    hasPermission(val: string): boolean {

        if (this.authService.isStakeholderUser()) {
            const token: StakeholderDecodedToken = this.authService.getBarereToken();

            if (token) {  //allowed modules
                const stakeholderUser = ["Projects", "profile"];
                const stakeholderOwner = ["Projects", "profile", "Owner", "Dashboard", "PCR", "RFI", "Project Updates", "Submittals", "Chat", "Billing"];
                const stakeholderArchitect = ["Projects", "profile", "Architect", "Dashboard", "PCR", "RFI", "Submittals","Chat"];


                if (this.stakeholderProjectService.userProjectRole.role == "owner") {

                    if (val.toLowerCase() == "pcr") {

                        if (this.stakeholderProjectService.userProjectRole.projPermission.pcr.approveReject)
                            return true;
                        else return false;
                    }
                    else if (val.toLowerCase() == "submittals") {
                        if (this.stakeholderProjectService.userProjectRole.projPermission.submittals.approveReject)
                            return true;
                        else return false;
                    }
                    else {
                        if (stakeholderOwner.some(x => x.toLowerCase() == val.toLowerCase()))
                            return true;
                        else return false;
                    }
                }
                else if (this.stakeholderProjectService.userProjectRole.role == "architect") {
                    if (stakeholderArchitect.some(x => x.toLowerCase() == val.toLowerCase()))
                        return true;
                    else return false;
                }
                else {
                    if (stakeholderUser.some(x => x.toLowerCase() == val.toLowerCase()))
                        return true;
                    else return false;
                }

            }
        }
        else if (!this.authService.isSubtraidEmployee()) {

            const token: DecodedToken = this.authService.getBarereToken();

            if (token) {

                const unemployedPermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList'];
                const basicPermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab', 'Projects', 'Projects_Dashboard', 'Scrumboard', 'Calendar', 'File Manager', 'file-manager'];
                const basicSubFalsePermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab'];
                const adminSubTruePermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab', 'Projects', 'Projects_Dashboard', 'Scrumboard', 'Calendar', 'Timesheets', 'Company', 'Offer_Employment', 'File Manager', 'file-manager', 'gantt-chart', 'Gantt Chart'];
                const adminSubFalsePermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab', 'Company'];

                // const comapnySubFalsePermissions = [ 'Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab', 'Projects', 'Projects_Dashboard', 'Scrumboard', 'Calendar', 'Timesheets', 'Company', 'Projects_Disable', 'Company_wide_events' ];
                const comapnySubFalsePermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab', 'Company'];
                const comapnySubTruePermissions = ['Home', 'Chat', 'Contacts', 'Profile', 'Chat_AllUsers', 'Chat_MyList', 'Profile_Tab', 'Subscription_Info', 'Projects', 'Projects_Dashboard', 'Scrumboard', 'Scrumboard_Submittal', 'Calendar', 'Timesheets', 'Company', 'Offer_Employment', 'Company_wide_events', 'File Manager', 'file-manager', 'gantt-chart', 'Gantt Chart', 'rfp', 'Request for Proposal'];



                if (token.isRootUser) {
                    if (token.isPaidAccount) { // Company user > Subscription = true


                        if (comapnySubTruePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                            return true;
                        } else {
                            return false;
                        }

                    } else { // Company user > Subscription = false

                        // if (this.validForFreeTrial(token)) {
                        if (token.isValidForTrial) {
                            if (comapnySubTruePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        else if (comapnySubFalsePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                            return true;
                        } else {
                            return false;
                        }

                    }
                } else {
                    if (!token.employer) { // Unemployed user     
                        // console.log( 'unemployued' )
                        if (unemployedPermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                            return true;
                        } else {
                            return false;
                        }

                    }
                    if (token.isAdmin) { // Admin permission

                        if (token.isPaidAccount) { // Company user > Subscription = true

                            // console.log( 'admin sub true' )
                            if (adminSubTruePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                return true;
                            } else {
                                return false;
                            }

                        } else {
                            // console.log( 'admin sub false' )
                            // if (this.validForFreeTrial(token)) {
                            if (token.isValidForTrial) {
                                if (adminSubTruePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                            else if (adminSubFalsePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                return true;
                            } else {
                                return false;
                            }

                        }

                    }

                    if (!token.isAdmin) { // Basic permission
                        // console.log( 'basic' )
                        if (token.isPaidAccount) { //basic sub true
                            if (basicPermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        else {
                            //basic sub false
                            // if (this.validForFreeTrial(token)) {
                            if (token.isValidForTrial) {
                                if (basicPermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                            else if (basicSubFalsePermissions.some(x => x.toLowerCase() == val.toLowerCase())) {
                                return true;
                            } else {
                                return false;
                            }
                        }


                    }
                }
            }
            else return true;  //before
        }
        else {
            //Subtraid employee
            const token: SubtraidEmployeeDecodedToken = this.authService.getBarereToken();

            if (token) {

                const superAdminPermission = ['Users', 'Platform Improvements', 'Basic Settings', 'employees', 'platform-improvements', 'profile', 'basic-settings'];
                const adminPermission = ['Platform Improvements', 'platform-improvements', 'profile'];

                if (token.isSuperAdmin) {
                    if (superAdminPermission.some(x => x.toLowerCase() == val.toLowerCase())) {

                        return true;
                    } else {

                        return false;
                    }
                }
                else if (adminPermission.some(x => x.toLowerCase() == val.toLowerCase()))
                    return true;
                else return false;

            }

        }

    }

    validForFreeTrial(token: DecodedToken) {

        //check for new registerd user
        const registerdOn = new Date(token.userDateCreated);
        const noOfDays = this.getDays(registerdOn, new Date());
        if (noOfDays >= 0 && noOfDays <= 30) { //new user
            return true;
        }
        else {   //old users
            const expiry = token.subscriptionExpiryDate;
            if (expiry == null) {
                return false;
            }
            else {
                const days = this.getDays(new Date(expiry), new Date());

                if (days <= 0 && days >= -30)
                    return true;
                else
                    return false;
            }
        }
    }

    getDays(gDate, lDate) {
        const diffMillisec = gDate - lDate;
        return Math.ceil(diffMillisec / (1000 * 3600 * 24));
    }

}