import { FuseUtils } from "@fuse/utils";

export class User {
    _id: string;
    name: string;
    img: string;
    isSubtraid: Boolean;
    accountType: string;
    role: string;
    email: string;
    employer: any;
    company: any;
    workspace: string;
    chatsUnreadCount: Number;
    //////new
    description: string;
    licences: any;
    phone: string;
    skills: any;
    experiences: any;
    isAvailable: boolean;
    location: any;
    projectHistory: any;
    services: string;
    unions: any;
    isPaidAccount: boolean;
    isValidForTrial: boolean;
    freeTrialRemainingExpiryDays: number;
    employerAndCompanyDetail?: any;
    education?: any;
    isUnion?: boolean;
    isApplyResignation?: boolean;
    isTerminated?: boolean;
    websiteUrl?: string;
    salaryExpectation?: any;
    doNotShowMsgIncompleteProfile?: boolean;
    currency?: any;  //used as company's currency
    companyCurrency?: any;  ///used as employee's company currency
    emailVerificationPending?: {
        email: string;
        isPending: boolean;
    }
    //////new end

    /**
     * Constructor
     *
     * @param user
     */
    constructor(user) {
        this._id = user._id;
        this.name = user.name || "";
        this.isSubtraid = user.isSubtraid || false;
        this.accountType = user.accountType || "user";
        this.role = user.role || "laborer";
        this.email = user.email || "";
        this.employer = user.employer || null;
        this.company = user.company || null;
        this.workspace = user.workspace || "";
        this.img = user.img || "/assets/images/invisible.png";
        this.chatsUnreadCount = user.chatsUnreadCount || 0;
        //////new
        this.description = user.description || "";
        this.licences = user.licences || null;
        this.phone = user.phone || "";
        this.skills = user.skills || null;
        this.experiences = user.experiences || null;
        this.isAvailable = user.isAvailable || false;
        this.location = user.location || null;
        this.projectHistory = user.projectHistory || null;
        this.services = user.services || "";
        this.unions = user.union || null;
        this.isPaidAccount = user.isPaidAccount || false;
        this.isValidForTrial = user.isValidForTrial || false;
        this.freeTrialRemainingExpiryDays = user.freeTrialRemainingExpiryDays || -1;
        this.employerAndCompanyDetail = user.employerAndCompanyDetail || null;
        this.education = user.education || [];
        this.isUnion = user.isUnion || false;
        this.isApplyResignation = user.isApplyResignation || false;
        this.isTerminated = user.isTerminated || false;
        this.websiteUrl = user.websiteUrl || "";
        this.salaryExpectation = user.salaryExpectation || [];
        this.doNotShowMsgIncompleteProfile = user.doNotShowMsgIncompleteProfile || false;
        this.currency = user.currency || null;
        this.companyCurrency = user.companyCurrency || null;
        this.emailVerificationPending = user.emailVerificationPending;
        //////new end
    }
}
