import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../../environments/environment.uat";
//Subtraid
import { AuthService } from "../../../../../main/services/auth.service";
import { StakeholderProjectsService } from "app/main/pages/stakeholder-users/pages/stakeholder-projects/stakeholder-projects.service";

@Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    userName: string;
    userEmail: string;
    userImg: string;
    isSubtraid: Boolean;
    companies: [any];
    basePath = environment.basePath;
    isSubtraidEmployee = false;
    isStakeholder = false;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private http: HttpClient,
        private AuthService: AuthService,
        private stakeHolderProjectService: StakeholderProjectsService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.isSubtraidEmployee = this.AuthService.isSubtraidEmployee();
        this.isStakeholder = this.AuthService.isStakeholderUser();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective)
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector(
                        "navbar .nav-link.active"
                    );

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop,
                            activeItemOffsetParentTop =
                                activeNavItem.offsetParent.offsetTop,
                            scrollDistance =
                                activeItemOffsetTop -
                                activeItemOffsetParentTop -
                                48 * 3 -
                                168;

                        this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            });


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar("navbar")) {
                    this._fuseSidebarService.getSidebar("navbar").close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        // for already login user to pull in user data
        if (this.AuthService.getBarereToken() !== null && !this.AuthService.firstTimeLogin) {
            this.AuthService.refreshUser();
        }

        // Whenever user chagned
        this.AuthService.user
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                this.userImg = user.img;
                this.userName = user.name;
                this.userEmail = user.email;
                this.isSubtraid = user.isSubtraid;
                
                this.updateSideBarChatBadge(user.chatsUnreadCount);
            });

        this.stakeHolderProjectService.projectDetail
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res) {

                    this.stakeHolderProjectService.setNavigation(res._id, res.loginUserProjectDetails, res.isCollaborated);
                    this._fuseNavigationService.updateNavigationItem("shchat", {
                        function: () => {
                            this._fuseNavigationService.updateNavigationItem("shchat", {
                                badge: {
                                    title: 0,
                                    bg: "#ea1601",
                                    fg: "white",
                                },
                            });

                            this.updateChatUnreadCount(0);
                        },
                    });
                }
            });

        this.stakeHolderProjectService.removeSHNavigation
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this._fuseNavigationService.removeNavigationItem("owner");
                this._fuseNavigationService.removeNavigationItem("architect");
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update SideBar Chat Badge
     * @param number
     */

    updateSideBarChatBadge(number) {
        // Update the badge title
        this._fuseNavigationService.updateNavigationItem("chat", {
            badge: {
                title: number,
                bg: "red",
                fg: "white",
            },
            function: () => {
                this._fuseNavigationService.updateNavigationItem("chat", {
                    badge: {
                        title: 0,
                        bg: "red",
                        fg: "white",
                    },
                });

                this.updateChatUnreadCount(0);
            },
        });
    }

    /**
     * Update Chat Unread Count
     * @param newCount
     */

    updateChatUnreadCount(newCount) {
        const endPoint: string = "/users/chatunreadcount";

        let payload = {
            newCount: newCount,
        };

        this.http.post(this.basePath + endPoint, payload).subscribe(
            (data) => { },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Toggle sidebar opened status
     */

    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
        //this._fuseSidebarService.getSidebar("navbar").toggleAutoOpen();
    }

    /**
     * @function
     *    calling the authserivce logout function to log user out and redirect to the login page
     * @param
     * @return
     */

    onLogOut() {
        this.AuthService.logOut();
    }


}
