import { HasPermissionDirective } from './hasPermission.directive';
import { IsCompanyUserDirective } from './isCompanyUser.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDragAndDropDirective } from './file-drag-and-drop.directive';
import { CustomMatSelectDirective } from './custom-mat-select.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { ContentEditableDirective } from './content-editable.directive';
import { DisableControlDirective } from './disable-control.directive';
import { ScrollSpyDirective } from './scroll-spy.directive';


@NgModule({
  declarations: [
    IsCompanyUserDirective,
    HasPermissionDirective,
    FileDragAndDropDirective,
    CustomMatSelectDirective,
    AutoFocusDirective,
    ContentEditableDirective,
    DisableControlDirective,
    ScrollSpyDirective,
    
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IsCompanyUserDirective,
    HasPermissionDirective,
    FileDragAndDropDirective,
    CustomMatSelectDirective,
    AutoFocusDirective,
    ContentEditableDirective,
    DisableControlDirective,
    ScrollSpyDirective
  ]
})
export class DirectiveModule { }
